@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* html, body { height: 100%; } */
body {
  margin-top: 1rem;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#divElement {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}

​ .loader {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  position: relative;
  animation: spin 4s linear infinite;
}

.loader:before,
.loader:after {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 14px;
  box-shadow: 18px 18px #0DB14C, -18px -18px #0189D2;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  animation: load 1.2s linear infinite;
  z-index: 100;
}

.loader:after {
  box-shadow: 18px 18px #6461AB, -18px -18px #CD014F;
  transform: translate(-50%, -50%) rotate(90deg);
}

@keyframes load {
  50% {
    height: 42px;
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 600px) {
  .hideonmobile {
    visibility: hidden;
    display: none;
  }
}
.vlglobal {
  border-left: 3px solid orangered;
  height: 30px;
}